import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tooltip, Link } from "@mui/material";
import RiskSlider from "./RiskSlider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function RiskScoreTable({ data }: any) {
  return (
    <TableContainer sx={{ borderRadius: "10px" }}>
      <Table size="small" aria-label="a dense table">
        <TableHead></TableHead>
        <TableBody>
          <TableRow sx={{ height: "80px" }}>
            <Tooltip
              title={
                <React.Fragment>
                  "Comprehensive assessment of timber illegality associated with
                  specific countries. Source: Preferred by Nature Timber
                  Sourcing Hub. For more information, see the{" "}
                  <Link
                    href="/understand-risk"
                    target="_blank"
                    style={{ color: "#6db2b8" }}
                  >
                    Understanding Risk section
                  </Link>
                  .{" "}
                </React.Fragment>
              }
              placement="top-start"
            >
              <TableCell>
                Timber Sourcing Hub Risk Score
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell
              width="220px"
              sx={{
                textAlign: "center",
                "&:hover": { cursor: "default" },
              }}
            >
              {data?.timber_risk_score !== null ? (
                <RiskSlider
                  sliderType={"normal"}
                  data={data?.timber_risk_score}
                />
              ) : (
                "No Data"
              )}
            </TableCell>
            <TableCell align="right">0 is most risky, 100 is least</TableCell>
          </TableRow>
          <TableRow sx={{ height: "80px" }}>
            <Tooltip
              placement="top-start"
              title={
                <React.Fragment>
                  Measure of perceived government corruption as determined by a
                  combination of surveys and independent assessment. Updated
                  annually. Source: Transparency International For more
                  information, see the{" "}
                  <Link
                    href="/understand-risk"
                    target="_blank"
                    style={{ color: "#6db2b8" }}
                  >
                    Understanding Risk section
                  </Link>
                  .{" "}
                </React.Fragment>
              }
            >
              <TableCell>
                Corruption Perceptions Index (CPI) Score
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell width="200px" sx={{ textAlign: "center" }}>
              {data?.cpi_score !== null ? (
                <RiskSlider sliderType={"normal"} data={data.cpi_score} />
              ) : (
                "No Data"
              )}
            </TableCell>
            <TableCell align="right">0 is most corrupt, 100 is least</TableCell>
          </TableRow>
          <TableRow sx={{ height: "80px" }}>
            <Tooltip
              title={
                <React.Fragment>
                  Standardized measure of six measures for public integrity and
                  transparency. Updated annually. Source: CorruptionRisk.org.
                  For more information, see the{" "}
                  <Link
                    href="/understand-risk"
                    target="_blank"
                    style={{ color: "#6db2b8" }}
                  >
                    Understanding Risk section
                  </Link>
                  .{" "}
                </React.Fragment>
              }
              placement="top-start"
            >
              <TableCell>
                Index of Public Integrity (IPI) Score
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell sx={{ textAlign: "center" }}>
              {data?.ipi_score ? (
                <RiskSlider sliderType={"other"} data={data.ipi_score} />
              ) : (
                "No Data"
              )}
            </TableCell>
            <TableCell align="right">
              10 is most able to control corruption, 1 is least
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
