import React from 'react'
import { useTheme } from '@mui/material/styles'
import {Box, Link, Typography} from '@mui/material'

function Footer() {
    const theme = useTheme();

    return (
      <>
        {/* <div style={{backgroundColor: '#000'}}>
          <hr style={{border: 'none', width: '90vw', height: '2px', margin: '0 auto', backgroundColor: '#fff'}} />
        </div> */}
        <Box 
          sx={{
            // display: 'flex',
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            marginTop: 'auto',
            padding: '10px',
            width: '100%',
            fontSize: '12px'
            // alignItems: 'center',
            // justifyContent: 'space-evenly'
        }}
        >
          <div style={{width: '70%', margin: '0 auto'}}>
            <p style={{marginBottom: '1rem'}}><a href='/photo-credits' style={{color: 'white'}}>Photo Credits</a></p>
            <p>Terms of Use. All use of WWF’s Wood Risk Tool (WRT) is subject to <a style={{color: '#bbb'}} href="https://www.worldwildlife.org/pages/privacy-policy" target='_blank' rel="noreferrer">WWF’s privacy policy</a> and <a style={{color: '#bbb'}} href="https://www.worldwildlife.org/pages/site-terms" target='_blank' rel="noreferrer">WWF’s site terms</a>. Please note that the WRT is being provided on an “as is” basis and for educational purposes only, and information obtained through the WRT should not be relied upon as legal advice. Please seek qualified professional counsel when undertaking legal and regulatory compliance relating to responsible sourcing.</p>
            <p>While WWF intends to maintain the WRT in furtherance of its conservation mission, WWF makes no representations that it will do so nor that the information contained within the WRT is/will be current, up-to-date, accurate, or comprehensive. WWF will not be liable for any damages or losses incurred as a direct or indirect result of your use of the WRT or that of any other party.</p>
            <p>WWF welcomes user input with the goal of continuous improvement. If you believe you have identified an error or have any other suggestions, please contact us: <a style={{color: '#bbb'}} href="mailto:info@woodrisk.org">info@woodrisk.org</a></p>
          </div>
        </Box>
      </>
    )
}

export default Footer