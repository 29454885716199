import React from 'react'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import { Box, Button, Typography, Menu, MenuItem } from '@mui/material'

interface MenuProps {
  name: string;
  links: {
    name: string;
    path: string;
  }[]
}

export default function MenuWithDropdown({name, links}: MenuProps) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box flexDirection='row' alignSelf='center' onMouseLeave={handleClose}>
            <Button
                // aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                //onClick={handleClick}
                onMouseOver={handleClick}
                //onMouseLeave={handleClose}
                style={{ textDecoration: 'none', color: theme.palette.primary.contrastText }}
            >
                <Typography variant='body2'>{name}</Typography>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
            >
                {links.map((link, index) => {
                    return (
                        <MenuItem component={Link} to={link.path} onClick={handleClose} key={index}><Typography variant='body2'>{link.name}</Typography></MenuItem>
                    )
                })}
            </Menu>

        </Box>
    )
}