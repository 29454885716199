import { GridColDef } from '@mui/x-data-grid';


export const countriesUrl = 'https://wagtail.woodrisk.org/wagtail/api/v2/pages/?type=main.Country'

export const convertFromHtml = (input: any) => {
  return { __html: input }
}

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  overflow: 'auto',
  maxHeight: '95%',
  //boxShadow: 24,
  p: 4,
};

