import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import type { } from '@mui/x-data-grid/themeAugmentation';


declare module "@mui/material/styles" {
  interface Palette {
    sectionGrays: Palette["primary"];
    wwfGreen: Palette["primary"];
  }
  interface PaletteOptions {
    sectionGrays: PaletteOptions["primary"];
    wwfGreen: PaletteOptions["primary"];
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    wwfGreen: true
  }
}

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(","),
    body2: {
      fontFamily: ['WWFRegular', 'sans-serif'].join(','),
      fontSize: '22px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: '19px'
    },
    subtitle1: {
      fontSize: '15px'
    },
    h5: {
      fontFamily: ['WWFRegular', 'sans-serif'].join(','),
      fontSize: '15px',
      marginRight: '2px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    h3: {
      fontFamily: ['WWFRegular', 'sans-serif'].join(','),
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    h4: {
      //textDecoration: 'underline',
      fontFamily: ['WWFRegular', 'sans-serif'].join(','),
      padding: '10px',
      //marginBottom: '10px',
      background: '#6db2b8',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    }
  },
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#f5f5f5'
    },
    secondary: {
      main: '#6db2b8',
      contrastText: '#b7d8db'
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: '#d54559',
      contrastText: '#000'
    },
    info: {
      main: '#52803c',
      contrastText: '#000'
    },
    sectionGrays: {
      main: '#d3d3d3',
    },
    wwfGreen: {
      main: '#b7d8db',

    }

  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
          fontSize: '14px',
          letterSpacing: '0px',
          textTransform: 'none',
          marginBottom: '5px',
          cursor: 'pointer',
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          }
        },
        row: {
          "&.Mui-selected": {
            backgroundColor: "#ffffed",

          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
          fontSize: '15px',
          letterSpacing: '0px',
          textTransform: 'none',
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '&.Mui-Backdrop': {
            backgroundColor: 'white',
          }
        }
      }
    },
  }
});

export default theme;

