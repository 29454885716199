import { useState, useEffect } from "react";

// getting all results and filtering/ordering on client now
export default function useCountries(
  url = "https://wagtail.woodrisk.org/wagtail/api/v2/pages/?type=main.Country&fields=name,ipi_score,timber_risk_score,cpi_score,risk_summary_reports(*)"
) {
  const [allCountries, setCountries] = useState<any>([]);
  const [loadingCountries, setloadingCountries] = useState<any>(true);

  // const getCountries = async () => {
  //     const results = await fetch(url)
  //         .then((res) => res.json())
  //         .catch((err) => { console.log(err) })
  //     //console.log(results);
  //     setCountries(results)
  // }

  const updateCountries = async (url: string) => {
    let searchUrl = url.split(".com");

    //console.log(properurl);
    const results = await fetch(searchUrl[1])
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    //.log(results);
    setCountries(results);
  };

  const searchCountries = async (
    input?: string,
    sortOrder?: string,
    offset?: number,
    limit?: number
  ) => {
    setloadingCountries(true);
    const s = input ? `&search=${input}` : "";
    const sort = sortOrder ? `&order=${sortOrder}` : "";
    const o = offset ? `&offset=${offset}` : "";
    const l = limit ? `&limit=${limit}` : "";
    const searchTerm = `${url}${sort}${s}${o}${l}`;
    const results = await fetch(searchTerm)
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloadingCountries(false));
    if (results.count == 0) {
      console.log("nothing");
    }
    //console.log(results);
    setCountries(results);
  };

  // useEffect(() => {
  //     getCountries()
  // }, [])

  return { allCountries, updateCountries, searchCountries, loadingCountries };
}
