import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Tooltip,
  Link,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRowParams,
} from "@mui/x-data-grid";

interface Row {
  id: number;
  name: string;
  live: boolean;
  slug: string;
  score?: string;
  ipi_score?: string;
  timber_risk_score?: string;
  cpi_score?: string;
}

const CountriesInSpecies = ({ props }: any) => {
  const [rows, setRows] = useState<Row[]>([]);
  const navigate = useNavigate();

  const CustomToolTip = (name: string, tooltipString: string) => {
    return (
      <Tooltip title={tooltipString} placement="top">
        <div className="MuiDataGrid-columnHeaderTitle css-1jbbcbn-MuiDataGrid-columnHeaderTitle">
          {name}
        </div>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Country",
      //customHeadRender: () => null,
      editable: false,
      //sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Tooltip
          title={
            params.row.live
              ? "Click to View Country Details"
              : "No Country Information Tracked"
          }
          placement="right"
        >
          <Box className="csutable-cell-trucate" sx={{ textAlign: "center" }}>
            {params.row.name}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "ipi_score",
      //headerName: "Index of Public Integrity (IPI) Score",
      renderHeader: (params: GridColumnHeaderParams) =>
        CustomToolTip(
          "Index of Public Integrity (IPI) Score",
          "Standardized measure of six measures for public integrity and transparency. Updated annually.  Source: CorruptionRisk.org."
        ),
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "timber_risk_score",
      //headerName: "Timber Sourcing Hub Risk Score",
      renderHeader: (params: GridColumnHeaderParams) =>
        CustomToolTip(
          "Timber Sourcing Hub Risk Score",
          "Comprehensive assessment of timber illegality associated with specific countries. Source: Preferred by Nature Timber Sourcing Hub."
        ),
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cpi_score",
      headerName: "Corruption Perceptions Index (CPI) Score",
      renderHeader: (params: GridColumnHeaderParams) =>
        CustomToolTip(
          "Corruption Perceptions Index (CPI) Score",
          "Measure of perceived government corruption as determined by a combination of surveys and independent assessment.  Updated annually. Source: Transparency International"
        ),
      // headerName: '',
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "score",
      headerName: "FSC NRA Specified  Risk Indicators",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Tooltip
          title={
            <React.Fragment>
              The number of 32 discreet indicators evaluated by FSC National
              Risk Assessments that is determined to have elevated risk
              associated with a specific attribute. Control measures are
              identified for mitigating Specified Risk. Sources: FSC
              International Document Center and FSC Risk Assessment Platform.
              For more information, see the{" "}
              <Link
                href="/understand-risk"
                target="_blank"
                style={{ color: "#6db2b8" }}
              >
                Understanding Risk section
              </Link>
              .{" "}
            </React.Fragment>
          }
          placement="top"
        >
          <div className="MuiDataGrid-columnHeaderTitle css-1jbbcbn-MuiDataGrid-columnHeaderTitle">
            FSC NRA Specified  Risk Indicators
          </div>
        </Tooltip>
      ),
      editable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Box className="csutable-cell-trucate" sx={{ textAlign: "center" }}>
          {params.row.score}
        </Box>
      ),
    },
  ];

  const trackedCountry = (e: any) => {
    //console.log(e.id);
    //console.log(e.target.textContent);
    //console.log(e.target.id);
    //console.log(e.name);
    //console.log(e.live);
    if (e.live) {
      navigate(`/assess-risk/countries/${e.slug}`, {
        state: { id: e.id, name: e.name, slug: e.slug },
      });
    }
  };

  useEffect(() => {
    setRows([]);

    props.map((country: any) => {
      //console.log(typeof country.live);
      console.log(country);

      let entryRow = {
        id: country.id,
        name: country.name,
        live: country.live,
        slug: country.meta.slug,
        score:
          country.risk_summary_reports.length > 0
            ? country.risk_summary_reports[0].total_score
            : "No Risk Information Provided",
        ipi_score: country?.ipi_score,
        cpi_score: country?.cpi_score,
        timber_risk_score: country?.timber_risk_score,
        // ipi_score: country.ipi_score ? country.ipi_score : 'No Data',
        // cpi_score: country.cpi_score ? country.cpi_score : 'No Data',
        // timber_risk_score: country.timber_risk_score ? country.timber_risk_score : 'No Data',
      };
      setRows((rows) => [...rows, entryRow]);
    });
  }, []);

  return (
    <DataGrid
      className="countriesInSpecies"
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      sx={{
        marginTop: "10px",
        fontSize: "17px",
        width: "100%",
        height: "400px",
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.2,
        },
        // "& .MuiDataGrid-row:hover": {
        //   backgroundColor: "skyblue",
        //   // color: "red"
        // },
      }}
      headerHeight={80}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      onRowClick={(params: GridRowParams<any>) => {
        console.log(params.row.live);
        if (params.row.live) {
          trackedCountry(params.row);
        }
      }}
      isRowSelectable={(params: GridRowParams<any>) => params.row.live}
      hideFooter
      //autoHeight
    />
  );
};

//Assess Country Risk
//Asses SPecies

//Timber Sourcing Hub

//IPI

//3.0 risk of access
//add GMO's to Summary

//Summary
//Detailed

export default CountriesInSpecies;
