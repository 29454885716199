import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function SpeciesInfoTable({ data }: any) {
  return (
    <TableContainer sx={{ borderRadius: "10px" }}>
      <Table size="small" aria-label="a dense table">
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <Tooltip
              title={
                "A scientific classification system that uses a binomial nomenclature comprised of a genus and species epithet for proving a unique identity to every known species.   Source: Kew Royal Botanical Gardens Plants of the World Online."
              }
              placement={"top-start"}
            >
              <TableCell>
                Scientific Name
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell align="left">
              <b>{data?.scientific_name || "No Data"}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <Tooltip
              title={
                "One or more names, typically of local or regional origin, used by local people and commercial traders to describe individual species.  The same common names can be used to describe multiple species and are therefore a potential source of confusion or misidentification."
              }
              placement={"top-start"}
            >
              <TableCell>
                Common Names
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell>
              <b>
                {data?.common_names
                  .map((names: any) => names.common_name)
                  .join(", ") || "No Data"}
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <Tooltip
              title={
                "Listing of the most common uses for commercially traded products made from individual tree species."
              }
              placement={"top-start"}
            >
              <TableCell>
                Commercial Uses
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell>
              <b>
                {data?.commercial_uses.map((use: any) => use.name).join(", ") ||
                  "No Data"}
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <Tooltip
              title={
                <React.Fragment>
                  Scientifically-based assessment of the biological conservation
                  status of species around the world maintained by the
                  International Union of Conservation of Nature (IUCN).
                  Threatened categories include Critically Endangered (CR),
                  Endangered (EN) and Vulnerable (VU). Source: IUCN Red List.
                  For more information, see the{" "}
                  <Link
                    href="/understand-risk"
                    target="_blank"
                    style={{ color: "#6db2b8" }}
                  >
                    Understanding Risk section
                  </Link>
                  .{" "}
                </React.Fragment>
              }
              placement={"top-start"}
            >
              <TableCell>
                IUCN Red List Category
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell>
              <b>{data?.iucn_red_listing?.name || "No Data"}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <Tooltip
              title={
                <React.Fragment>
                  List of global species protected by international agreement
                  against over-exploitation through international trade. Species
                  are listed in one of three appendices according to threat of
                  extinction. Species listed in CITES Appendix I are most
                  threatened. Maintained by the Convention on International
                  Trade in Endangered Species of Wild Fauna and Flora (CITES).
                  Source: Species+ listing maintained by the US Environment
                  Program. For more information, see the{" "}
                  <Link
                    href="/understand-risk"
                    target="_blank"
                    style={{ color: "#6db2b8" }}
                  >
                    Understanding Risk section
                  </Link>
                  .{" "}
                </React.Fragment>
              }
              placement={"top-start"}
            >
              <TableCell>
                CITES Listing
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell>
              <b>{data?.cites_listing?.name || "No Data"}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <Tooltip
              title={
                <React.Fragment>
                  "A measure of a species’ risk of illegal logging associated
                  with one or more countries of origin as determined by scores
                  of 50 or less on the Preferred by Nature Timber Sourcing Hub.
                  For more information, see the{" "}
                  <Link
                    href="/understand-risk"
                    target="_blank"
                    style={{ color: "#6db2b8" }}
                  >
                    Understanding Risk section
                  </Link>
                  .{" "}
                </React.Fragment>
              }
              placement={"top-start"}
            >
              <TableCell>
                Illegality Risk
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>
            <TableCell>
              <b>{data?.illegality_risk == true ? "Yes" : "No"}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <Tooltip
              title={
                "Typically broad-leaved deciduous tree species native to the warm tropical climatic zones proximate to the equator.  Tropical hardwood species are generally desired for durability and other attributes that leave them vulnerable to over-harvesting and illegal logging."
              }
              placement={"top-start"}
            >
              <TableCell>
                Tropical Hardwood
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>
              </TableCell>
            </Tooltip>

            <TableCell>
              <b>{data?.is_tropical_hardwood == true ? "Yes" : "No"}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
