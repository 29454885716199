import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Link,
  Paper,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridColumnHeaderParams,
  GridRowClassNameParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useCountriesIgnorePagination from "../hooks/useCountriesIgnorePagination";
import { BoxWrapper } from "../components/BoxWrapper";
import PageContainer from "./PageContainer";
import useCountries from "../hooks/useCountries";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Row {
  id: number;
  country: string;
  ipi_score: string;
  timber_score: string;
  cpi_score: string;
  legality: string;
  traditional: string;
  highConValue: string;
  conversion: string;
  gmo: string;
  slug: string;
}

export default function CompareCountries() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { allCountries, searchCountries } = useCountries();
  const [rows, setRows] = useState<Row[]>([]);
  const { state, pathname } = useLocation();

  useEffect(() => {
    searchCountries("", "name", 0, 300);
  }, []);

  const moveCountryToTop = (listOfCountries: any, countrySlug: string) => {
    console.log(countrySlug);
    const matchedFilteredCountries = listOfCountries.filter(
      (country: any) => country.meta.slug == countrySlug
    );
    const filteredCountries = listOfCountries.filter(
      (country: any) => country.meta.slug != countrySlug
    );
    console.log(matchedFilteredCountries);

    const reOrder = [...matchedFilteredCountries, ...filteredCountries];
    console.log(reOrder);

    reOrder.map((country: any) => {
      //console.log(country)

      let picked = {
        id: country.id,
        country: country.name,
        ipi_score: country.ipi_score !== null ? country.ipi_score : "",
        timber_score: country.timber_risk_score !== null ? country.timber_risk_score : "",
        cpi_score: country.cpi_score !== null ? country.cpi_score : "",
        legality: country.risk_summary_reports
          ? country?.risk_summary_reports[0]?.section_1_score
          : "",
        traditional: country.risk_summary_reports
          ? country?.risk_summary_reports[0]?.section_2_score
          : "",
        highConValue: country.risk_summary_reports
          ? country?.risk_summary_reports[0]?.section_3_score
          : "",
        conversion: country.risk_summary_reports
          ? country?.risk_summary_reports[0]?.section_4_score
          : "",
        gmo: country.risk_summary_reports
          ? country?.risk_summary_reports[0]?.section_5_score
          : "",
        total:
          Number(country?.risk_summary_reports[0]?.section_1_score) +
          Number(country?.risk_summary_reports[0]?.section_2_score) +
          Number(country?.risk_summary_reports[0]?.section_3_score) +
          Number(country?.risk_summary_reports[0]?.section_4_score) +
          Number(country?.risk_summary_reports[0]?.section_5_score),
        slug: country.meta.slug,
      };
      setRows((rows) => [...rows, picked]);
    });
  };

  const CustomToolTip = (name: string, tooltipString: string) => {
    return (
      <Tooltip title={tooltipString} placement="top">
        <div className="MuiDataGrid-columnHeaderTitle css-1jbbcbn-MuiDataGrid-columnHeaderTitle">
          {name}
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    setRows([]);
    ///console.log(allCountries.results);
    if (allCountries.items) {
      const countrySlug: string = searchParams.get("country") as string;
      moveCountryToTop(allCountries.items, countrySlug);
    }
  }, [allCountries, searchParams]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "country",
      headerName: "Country",
      editable: false,
      sortable: true,
      flex: 1.3,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "legality",
      headerName: "Legality",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "traditional",
      headerName: "Traditional and Civil Rights",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "highConValue",
      headerName: "High Conservation Values",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "conversion",
      headerName: "Conversion",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "gmo",
      headerName: "GMOs",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "total",
      headerName: "Total",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ipi_score",
      //headerName: "Index of Public Integrity (IPI) Score",
      renderHeader: (params: GridColumnHeaderParams) =>
        CustomToolTip(
          "Index of Public Integrity (IPI) Score",
          "Standardized measure of six measures for public integrity and transparency. Updated annually.  Source: CorruptionRisk.org."
        ),
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "timber_score",
      //headerName: "Timber Sourcing Hub Risk Score",
      renderHeader: (params: GridColumnHeaderParams) =>
        CustomToolTip(
          "Timber Sourcing Hub Risk Score",
          "Comprehensive assessment of timber illegality associated with specific countries. Source: Preferred by Nature Timber Sourcing Hub."
        ),
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cpi_score",
      headerName: "Corruption Perceptions Index (CPI) Score",
      renderHeader: (params: GridColumnHeaderParams) =>
        CustomToolTip(
          "Corruption Perceptions Index (CPI) Score",
          "Measure of perceived government corruption as determined by a combination of surveys and independent assessment.  Updated annually. Source: Transparency International"
        ),
      // headerName: '',
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const columnGroups: GridColumnGroupingModel = [
    {
      groupId: "Risk_scores",
      headerName: "FSC NRA Specified Risk Indicators",
      description: "",
      renderHeaderGroup: (params) => (
        <Tooltip
          title={
            <React.Fragment>
              The number of 32 discreet indicators evaluated by FSC National
              Risk Assessments that is determined to have elevated risk
              associated with a specific attribute. Control measures are
              identified for mitigating Specified Risk. Sources: FSC
              International Document Center and FSC Risk Assessment Platform.
              For more information, see the{" "}
              <Link
                href="/understand-risk"
                target="_blank"
                style={{ color: "#6db2b8" }}
              >
                Understanding Risk section
              </Link>
              .{" "}
            </React.Fragment>
          }
          placement="top"
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "500",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            {params.headerName}
          </Typography>
        </Tooltip>
      ),
      headerClassName: "risk-scores-class",
      headerAlign: "center",
      children: [
        { field: "legality" },
        { field: "traditional" },
        { field: "highConValue" },
        { field: "conversion" },
        { field: "gmo" },
        { field: "total" },
      ],
    },
    // {
    //     groupId: 'countries',
    //     headerName: 'Country',
    //     description: '',
    //     // renderHeaderGroup: (params) => (
    //     //     <Button sx={{ width: '10%' }} variant='contained' onClick={() => navigate(-1)}>Back</Button>
    //     // ),
    //     headerClassName: 'back-button-compare',
    //     headerAlign: 'center',
    //     children: [
    //         { field: 'country' },
    //
    //     ]
    // },
    // {
    //     groupId: 'ipi',
    //     headerName: 'Index of Public Integrity (IPI) Score',
    //     description: '',
    //
    //     //headerClassName: 'back-button-compare',
    //     headerAlign: 'center',
    //     children: [
    //         { field: 'ipi_score' },
    //
    //     ]
    // },
    // {
    //     groupId: 'timber',
    //     headerName: 'Timber Sourcing Hub Risk Score',
    //     description: '',
    //     // renderHeaderGroup: (params) => (
    //     //     <Button sx={{ width: '10%' }} variant='contained' onClick={() => navigate(-1)}>Back</Button>
    //     // ),
    //     //headerClassName: 'back-button-compare',
    //     headerAlign: 'center',
    //     children: [
    //         { field: 'timber_score' },
    //
    //     ]
    // },
    // {
    //     groupId: 'cpi',
    //     headerName: 'Corruption Perceptions Index (CPI) Score',
    //     description: '',
    //     // renderHeaderGroup: (params) => (
    //     //     <Button sx={{ width: '10%' }} variant='contained' onClick={() => navigate(-1)}>Back</Button>
    //     // ),
    //     //headerClassName: 'back-button-compare',
    //     headerAlign: 'center',
    //     children: [
    //         { field: 'cpi_score' },
    //
    //     ]
    // },
  ];

  // useEffect(() => {
  //     allCountriesIgnorePagination()
  // }, [])

  const handleCellSelect = (params: GridCellParams) => {
    //console.log(params.id);
    // console.log(params.value);
    navigate(`/assess-risk/countries/${params.row.slug}`, {
      state: {
        id: params.id,
        name: params.value,
        slug: params.row.slug,
      },
    });
  };

  return (
    <PageContainer style={{ alignItems: "center" }} title={"Compare"}>
      <Box width="90%">
        <Paper elevation={3}>
          <DataGrid
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroups}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            loading={rows.length <= 0}
            rows={rows}
            columns={columns}
            disableColumnFilter
            getRowClassName={(params: GridRowClassNameParams<any>) => {
              if (params.row.slug === searchParams.get("country")) {
                return "highlighted";
              } else {
                return "";
              }
            }}
            hideFooter
            autoHeight
            headerHeight={80}
            disableColumnMenu={true}
            sx={{
              marginTop: "10px",
              width: "100%",
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1.2,
              },
              "& .highlighted": {
                backgroundColor: theme.palette.wwfGreen.main,
              },
            }}
            onCellClick={(params: GridCellParams) => {
              handleCellSelect(params);
            }}
          />
        </Paper>
      </Box>
    </PageContainer>
  );
}
