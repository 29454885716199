import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import woodHeader from '../imgs/Medium_WW240987.jpg';

type Props = {
    title: string
}

function PageHeader({ title }: Props) {
    const theme = useTheme();
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            //height='10vh'

            height='200px'
            width='100%'
            id='page-header'
            sx={{
                background: theme.palette.secondary.main,
                marginBottom: '15px',
                backgroundImage: `url(${woodHeader})`,
                color: 'white',
                backgroundSize: 'cover',
                backgroundBlendMode: 'multiply',
                backgroundColor: 'rgba(0,0,0,0.25)',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <Typography sx={{ zIndex: '1000' }} component="h1" variant='h3'>{title}</Typography >
            {/* <img style={{ position: 'absolute', height: '200px', width: '500px' }} src={faker.image.nature(500, 200)} /> */}
            {/* <img style={{ height: '200px', width: '500px' }} src={headerImage} /> */}

        </Box>
    )
}

export default PageHeader